import Vue from "vue"
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"
import App from "./App.vue"
import DefaultLayout from "./layouts/Default.vue"
import DashboardLayout from "./layouts/Dashboard.vue"
import router from "./router"
import store from "./store"
import VueLazyload from 'vue-lazyload'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)
import VViewer from "@michaelray/vue-viewer";
Vue.use(VViewer);

// import './plugins/click-away'

import "./scss/app.scss"
import "./assets/fonts/fonts.css"
import "./assets/fonts/iconfont.css"
import "./assets/fonts/index.css"
import "./assets/css/theme.scss"

const script = document.createElement("script")
script.src = "https://img.nymaite.com/nymaite_com/static/m/js/jquery.min.js"
document.head.appendChild(script)

const mscript = document.createElement("script")
mscript.src = "https://console.nymaite.cn/assets/js/mt2.js"
document.body.appendChild(mscript)

import { http } from "./api/api"
Vue.prototype.$http = http

Vue.use(VueLazyload, {
	
  })
  
import { BASE_API } from "../env"
Vue.prototype.$BASE_API = BASE_API

// Vue.prototype.$UploadUrl = 'https://mettgpt.nymaite.cn/addons/chatgpt/midjourney/upload'
Vue.prototype.$UploadUrl = BASE_API + '/addons/chatgpt/midjourney/upload'


import VMdEditor from "@kangc/v-md-editor"
import "@kangc/v-md-editor/lib/style/base-editor.css"
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js"
import "@kangc/v-md-editor/lib/theme/style/vuepress.css"
import Prism from "prismjs"
import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index"
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css"
import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index"
import createTodoListPlugin from "@kangc/v-md-editor/lib/plugins/todo-list/index"
import "@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css"
VMdEditor.use(vuepressTheme, { Prism }).use(createCopyCodePlugin()).use(createLineNumbertPlugin()).use(createTodoListPlugin())
Vue.use(VMdEditor)

Vue.use(Antd)

import waterfall from "vue-waterfall2"
Vue.use(waterfall)

Vue.config.productionTip = false

Vue.component("layout-default", DefaultLayout)
Vue.component("layout-dashboard", DashboardLayout)

// 注册一个全局自定义指令
Vue.directive('update-label', {
	inserted: function (el, binding) {
	  // 绑定input事件，当输入框内容改变时触发
	  el.addEventListener('input', function () {
		const node = binding.value.node;
		const newValue = el.value;
		// 触发父组件的方法来更新数据
		binding.value.updateData(node, newValue);
	  });
	}
  });

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount("#app")
